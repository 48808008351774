import React from 'react';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Hero from 'components/Hero';
import Section from 'components/Section';
import Column from 'components/Column';
import HideOn from 'components/HideOn';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

export default function PageIndex({ data }) {
  const theme = 'yellow';
  return (
    <Layout theme={theme}>
      <Seo title="Beach-Halle" keywords={['']} />
      <Hero
        theme={theme}
        image={data.beach.childImageSharp.fluid}
        titleFirst="Beach"
        titleSecond="Volleyball"
      />

      <Section>
        <Column bigger noPadding>
          <HideOn screen="mobile">
            <Img
              fluid={data.vision.childImageSharp.fluid}
              objectFit="cover"
              fadeIn
              objectPosition="50% 50%"
              alt=""
            />
          </HideOn>
        </Column>
        <Column alignItems="left" justifyContent="center">
          <p>Unsere Mission</p>
          <h3 style={{ marginTop: 10 }}>Eine zentrale Beach-Halle in Köln</h3>
          <p>Gibt es noch nicht. Das wollen wir ändern.</p>
          <Link className="button" to="/vision">
            lese hier unsere vision
          </Link>
        </Column>
      </Section>

      <Section theme={theme}>
        <Column>
          <h3>Deshalb sind wir auf der Suche nach</h3>
          <ul>
            <li>einem Grundstück / einer leerstehenden Halle</li>
            <li>Investoren, Sponsoren</li>
            <li>Mitstreitern, die sich engagieren wollen</li>
            <li>Architekten</li>
          </ul>
        </Column>
        <Column>
          <h3>Hast du</h3>
          <p>
            Kapital, eine Halle, ein Grundstück, besondere Fähigkeiten,
            Kontakte, Ideen, Infos, Bock mitzumachen oder alles auf einmal?
            Schreib uns ne mail!{' '}
            <a href="mailto:yeah@beach.koeln">yeah@beach.koeln</a>
          </p>
          <p>Und: Spread the word!</p>
        </Column>
      </Section>

      <Section>
        <Column>
          <h3>Latest Update</h3>
          <h4>
            Bleibt gesund! <br />
          </h4>
          <p>
            Wir bleiben weiter dran und versuchen den Traum einer Beachvolleyballhalle in Köln zu verwirklichen!
          </p>
        </Column>
        <Column alignItems="left" justifyContent="center">
          <Img
            fixed={data.whatsapp.childImageSharp.fixed}
            fadeIn
            alt="whatsapp"
          />
          <h3>Neueste Infos direkt per Whatsapp!</h3>
          <p>
            Mit diesem Gruppen-Einladungslink kommst du in unsere
            Whatsapp-News-Gruppe und erfährst sofort, sobald es Neuigkeiten
            gibt. Keine Angst vor Spam: In dieser Gruppe können nur
            Gruppenadmins schreiben.
          </p>
          <p> Gib den Link gerne auch an deine Beach-Buddies weiter!</p>
          <a
            className="button"
            href="https://chat.whatsapp.com/DMMUOR6TcBeBHNN2On2Esp"
          >
            Gruppe beitreten
          </a>
        </Column>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    vision: file(relativePath: { eq: "vision.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beach: file(relativePath: { eq: "beach.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whatsapp: file(relativePath: { eq: "whatsapp.png" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
