import styled from '@emotion/styled';
import media from 'utils/mediaqueries'

export const HideOnStyled = styled('div')({

},
props =>
props.screen === 'mobile' && {
  display: props.biggerThan ? props.displayType :  'none',

  [media('tablet')]: {
    display: props.biggerThan ? 'none' : props.displayType,
  },
},
props =>
  props.screen === 'tablet' && {
    display: props.biggerThan ? props.displayType :  'none',

    [media('tabletFluid')]: {
      display: props.biggerThan ? 'none' : props.displayType,
    },
  },
props =>
  props.screen === 'tabletFluid' && {
    display: props.biggerThan ? props.displayType :  'none',

    [media('desktop')]: {
      display: props.biggerThan ? 'none' : props.displayType,
    },
  },
props =>
  props.screen === 'desktop' && {
    display: props.biggerThan ? props.displayType :  'none',

    [media('tabletFluid')]: {
      display: props.biggerThan ? 'none' : props.displayType,
    },
  },
)
