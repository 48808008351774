import React from 'react';
import PropTypes from 'prop-types';

import { HideOnStyled } from './HideOnStyled.js';

export default function HideOn({ children, screen, biggerThan, displayType }) {
  return (
    <HideOnStyled screen={screen} biggerThan={biggerThan} displayType={displayType}>
      {children}
    </HideOnStyled>
  );
}

HideOn.propTypes = {
  children: PropTypes.node.isRequired,
  screen: PropTypes.string,
  biggerThan: PropTypes.bool,
  displayType: PropTypes.string,
};

HideOn.defaultProps = {
  screen: '',
  biggerThan: false,
  displayType: 'block'
};
